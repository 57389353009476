import i18n from '@/vue-app/plugins/i18n';
import TYPES from '@/types';
import Vue from 'vue';

// Application
import CreateLinkSowosWealthCommand from '@/modules/flagship/investor-goal/link-sowos-wealth/application/commands/create-link-sowos-wealth-command';
import { ActivateRecurringContributionCommand } from '@/modules/my-investment/allianz/allianz-account/application/commands';
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';
import {
  SearchAllianzAccountReportQuery,
} from '@/modules/my-investment/allianz/allianz-account-report/application/queries';

// Domain
import {
  InvestorGoalsDto,
} from '@/modules/flagship/investor-goal/link-sowos-wealth/domain/dtos/investor-goals-dto';
import {
  AllianzAccountReportEntity,
} from '@/modules/my-investment/allianz/allianz-account-report/domain/entities/allianz-account-report-entity';
import {
  ActivateRecurringContributionEntity,
} from '@/modules/my-investment/allianz/allianz-account/domain/entities/activate-recurring-contribution-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type GoalsAmountInformation = {
  active_goal_amount: number;
  investor_goal_id: string;
  label: string;
  recommend_amount: number;
}

export default class StepSchedulePaymentDateWealthViewModel {
  @Inject(TYPES.CREATE_LINK_SOWOS_WEALTH_COMMAND)
  private readonly create_link_wealth_command!: CreateLinkSowosWealthCommand;

  @Inject(TYPES.ACTIVATE_RECURRING_CONTRIBUTION_COMMAND)
  private readonly activate_recurring_contribution_command!: ActivateRecurringContributionCommand;

  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.SEARCH_ALLIANZ_ACCOUNT_REPORT_QUERY)
  private readonly search_allianz_account_report_query!: SearchAllianzAccountReportQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  private readonly view!: Vue;

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly error_allianz_account_not_exists = `Error customer_id:${this.customer_id}, not exists`;

  readonly i18n_namespace = 'components.flagship.flagship-link-goals.step-schedule-payment-date-wealth';

  AVAILABLE_DAYS = [...Array(28).keys()].map((i) => ({ item: `${i18n.t(`${this.i18n_namespace}.select_option`, { option: i + 1 })}`, value: i + 1 }));

  private investor_goals_dto: InvestorGoalsDto = {
    total_active_goals_balance: '0.00',
    price_by_unit: '0.00',
    unassigned_balance: '0',
    recurrent_unassigned_amount: '',
    investor_goals: [],
  };

  private goals_amount_information: Array<GoalsAmountInformation> = [];

  private recurrent_amount = '';

  private payload_activate_recurring_contribution: ActivateRecurringContributionEntity= {
    id: '',
    monthly_collection_day: 0,
    amount: '',
    periodicity: '',
    confirm_periodicity: true,
    home_desire: true,
  };

  funds: Array<AllianzAccountReportEntity> = [];

  price_by_unit = 0;

  change_date = true;

  payment_day = 0;

  has_recurring_contributions = true;

  recurrent_assigned_amount = 0;

  skip_steep = true;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (value: string, options?: Record<string, string | number>) => {
    if (options) {
      return i18n.tc(`${this.i18n_namespace}.${value}`, 0, options) as string;
    }
    return i18n.t(`${this.i18n_namespace}.${value}`) as string;
  }

  // eslint-disable-next-line max-len
  initialize = async (investor_goals: any, goals_amount_information: any, new_recurrent_amount: string) => {
    this.view.$emit('loadingStep', true);
    this.investor_goals_dto = investor_goals;
    this.goals_amount_information = goals_amount_information;
    if (new_recurrent_amount === '0') {
      await this.endProcessWithoutChangeOnRecurringContribution();
    }
    if (new_recurrent_amount !== '0') {
      this.skip_steep = false;
      this.recurrent_amount = new_recurrent_amount;
      await this.loadAllianzAccountData();
      this.hasRecurringContributions();
    }
    this.view.$emit('loadingStep', false);
  }

  loadAllianzAccountData = async () => {
    try {
      const {
        id, monthly_collection_day, amount,
      } = await this.get_allianz_account_query.execute({
        customer_id: this.customer_id,
      });
      this.payment_day = monthly_collection_day || 1;
      this.recurrent_assigned_amount = amount || 0;
      this.payload_activate_recurring_contribution = {
        id,
        monthly_collection_day: monthly_collection_day || 0,
        amount: this.recurrent_amount,
        periodicity: 'MENSUAL',
        confirm_periodicity: true,
        home_desire: true,
      };
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_allianz_account_data'));
      }
    }
  };

  createPayloadToLinkGoalsWithWealth = () => {
    // eslint-disable-next-line max-len
    this.investor_goals_dto.recurrent_unassigned_amount = this.investor_goals_dto.recurrent_unassigned_amount.toString();
    Object.entries(this.investor_goals_dto.investor_goals).forEach((object) => {
      const index = this.goals_amount_information.findIndex(
        (item) => item.investor_goal_id === object[1].investor_goal_id,
      );
      if (index !== -1) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        object[1].recurrent_assigned_amount = this.goals_amount_information[index].active_goal_amount_field.replaceAll(/[^\d.-]/g, '');
      }
    });
  }

  createLinkSowosWealth = async () => {
    try {
      this.createPayloadToLinkGoalsWithWealth();
      await this.create_link_wealth_command.execute(this.investor_goals_dto);
      const linked_goals_event = new CustomEvent('wealth.linked.goal', { detail: { ...this.investor_goals_dto } });
      window.dispatchEvent(linked_goals_event);
      this.view.$emit('endProcess');
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.create_link_sowos_wealth'));
    }
  }

  updatePaymentDate = async () => {
    try {
      this.view.$emit('loadingStep', true);
      if (this.change_date) {
        this.payload_activate_recurring_contribution.monthly_collection_day = this.payment_day;
      }
      // eslint-disable-next-line max-len
      await this.activate_recurring_contribution_command.execute(this.payload_activate_recurring_contribution);
      await this.reloadAllianzData();
      await this.loadPriceByUnit();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_payment_date'));
    } finally {
      this.view.$emit('loadingStep', false);
    }
  }

  endProcessWithoutChangeOnRecurringContribution = async () => {
    try {
      this.view.$emit('loadingStep', true);
      await this.loadPriceByUnit();
      this.view.$emit('loadingStep', false);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_payment_date'));
    }
  }

  reloadAllianzData = async () => {
    try {
      // eslint-disable-next-line max-len
      const allianz_account = await this.get_allianz_account_query.execute({ customer_id: this.customer_id, reload: true });
      const recurring_contributions_updated_event = new CustomEvent('allianz.update.recurring.contribution', { detail: { ...allianz_account } });
      window.dispatchEvent(recurring_contributions_updated_event);
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_reload_allianz_data'));
      }
    }
  }

  hasRecurringContributions = () => {
    if (!this.recurrent_assigned_amount) {
      this.has_recurring_contributions = false;
      this.payment_day = 1;
    }
  }

  loadPriceByUnit = async () => {
    try {
      this.funds = await this.search_allianz_account_report_query.execute(this.customer_id);
      const found_founded = this.funds.find((fund) => fund.found_code === 'SWSRFP');
      if (found_founded) {
        this.price_by_unit = found_founded.units_value || 0;
        this.investor_goals_dto.price_by_unit = this.price_by_unit.toString();
        await this.createLinkSowosWealth();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_price_by_unit'));
    }
  }

  goToPrevStep = () => {
    this.view.$emit('prevStep');
  }
}
