






























































































































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import StepSchedulePaymentDateWealthViewModel from '@/vue-app/view-models/components/flagship/flagship-link-goals/steps-wealth/step-schedule-payment-date-wealth-view-model';

@Component({ name: 'StepSchedulePaymentDateWealth' })
export default class StepSchedulePaymentDateWealth extends Vue {
  @PropSync('investorGoals', { type: Object, required: true })
  investor_goals!: Record<string, any>;

  @PropSync('goalsAmountInformation', { type: Array, required: true })
  goals_amount_information!: Array<object>;

  @PropSync('newRecurrentAmount', { type: String, required: true })
  new_recurrent_amount!: string;

  @PropSync('isLoading', { type: Boolean, required: true, default: false })
  is_loading!: boolean;

  step_schedule_payment_date_model = Vue.observable(
    new StepSchedulePaymentDateWealthViewModel(this),
  );

  created() {
    this.step_schedule_payment_date_model.initialize(
      this.investor_goals,
      this.goals_amount_information,
      this.new_recurrent_amount,
    );
  }
}
